<script lang="ts" setup>
  import { Text, BaseButton } from '@bt/design-system'
  import { useFetchPressReleases } from '@/composables/useFetchPressReleases'

  const { fetchPressReleases } = useFetchPressReleases()
  const config = useRuntimeConfig()

  const { locale, t } = useI18n()

  const press = ref([])

  const props = defineProps<{
    blok: any
  }>()

  watch(
    () => props.blok.buttonTitle,
    () => {
      buttonData.value = getButtonData()
    }
  )

  const getButtonData = () => {
    return {
      title: props.blok.buttonTitle,
      href: props.blok.href,
      target: props.blok.target
    }
  }

  const mainPressPageUrl = () => {
    return formatSbPath(props.blok.mainPressPage?.story?.full_slug, locale.value)
  }

  const buttonData = ref(getButtonData())

  const getPress = async () => {
    press.value = await fetchPressReleases(locale.value, 2, 1, new Date().getFullYear())
  }

  getPress()

  const openPressRelease = (slug: string) => {
    return `${config.public.companyUrl}/${locale.value}/${mainPressPageUrl()}?press=${slug}`
  }
</script>

<template v-if="press">
  <div class="latest-news-wrapper">
    <div class="block-title">
      <Text
        :text="props.blok.title"
        type="h2"
      />
    </div>
    <div class="latest-press-wrapper">
      <div
        v-for="data in press"
        :key="data.id"
        class="press-element"
      >
        <div class="press-info">
          <div class="date">{{ data.day }} {{ t(`reusable.monthShort.${data.month}`) }} {{ data.year }}</div>
          <Text
            :text="data.title"
            class="press cursor-pointer"
            tag="h5"
            type="h5"
          />
        </div>
        <NuxtLink
          class="link-wrap"
          external
          :to="openPressRelease(data.slug)"
        >
          <BaseButton
            :title="$t('buttons.read-more')"
            class="latest-news-read-more"
            block-type="full"
            color="ghost"
          />
        </NuxtLink>
      </div>
    </div>
    <ButtonFullWidth :blok="buttonData" />
    <BlockDivider v-if="blok.showDivider" />
  </div>
</template>

<style lang="scss" scoped>
  @import '@/assets/styles/common.scss';

  .latest-news-wrapper {
    width: 100%;

    @include desktop-up {
      max-width: rem(738);
      margin: 0 auto rem(24);
    }

    .block-title {
      margin-bottom: rem(32);
      text-align: left;
    }
  }

  .latest-press-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: rem(24);

    .press-element {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      margin-bottom: rem(10);
      justify-content: space-between;

      &:last-child {
        border-bottom: 1px solid $divider;
        padding: 0 0 rem(32) 0;
        margin-bottom: rem(32);
      }

      @include desktop-up {
        flex-direction: row;
        width: 100%;
        border-bottom: 1px solid $divider;
        padding: 0 0 rem(32) 0;

        &:last-child {
          border: none;
          padding: 0;
        }
      }

      .latest-news-read-more {
        @include desktop-up {
          max-width: 135px;
        }
      }

      .date {
        color: $secondary;
        font-size: rem(16);
        line-height: rem(19);
        font-weight: 400;
        margin-bottom: rem(15);
      }

      .press-info {
        margin-bottom: rem(23);

        @include desktop-up {
          margin-bottom: 0;
        }
      }

      .link-wrap {
        width: 100%;
        min-width: rem(135);

        @include desktop-up {
          max-width: max-content;
        }
      }
    }
  }
</style>
